import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';

const featureFlags = [
  /*
  // Example usage
  {
    name: 'newsletter-popup',
    variations: ['control', 'on'],
    isEligible: () => true,
  },*/
  {
    name: 'TimeSpentRO_1',
    variations: ['on', 'control'],
    isEligible: () => true
  },
  {
    name: 'RT-1559-AdShield-script-on-BFDC',
    variations: ['control', 'on'],
    isEligible: (buzz) => true,
  },
];

export const serviceAbTests = [];

export const abTests = [
  CONSENT_FEATURE_FLAG,
  ...featureFlags,
  ...serviceAbTests,
];

export default abTests;
